import { useState, forwardRef, useEffect } from "react";
import { Slide, Typography, DialogTitle, DialogContent, Dialog, List, ListItem, Checkbox, FormControlLabel } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { TypographyTitleStyled, IconButtonStyled, DividerStyled, TypographySubTitleStyled, ListItemBlockStyled } from "./style/Popin.styled";
import patchNotesData from "../../patchNotes.json";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const displayContent = () => {
  return patchNotesData.map(({ title, content }, index, currentArray) => {
    return (
      <List key={index}>
        <ListItemBlockStyled disablePadding>
          <TypographyTitleStyled>{title}</TypographyTitleStyled>
          <List>
            {content.map(({ subtitle, subcontent }, index) => (
              <ListItem key={index} disablePadding>
                <List>
                  <ListItem disablePadding>
                    <TypographySubTitleStyled>{subtitle}</TypographySubTitleStyled>
                  </ListItem>
                  <ListItemBlockStyled disablePadding>
                    {subcontent.map((item) => (
                      <Typography>{item}</Typography>
                    ))}
                  </ListItemBlockStyled>
                </List>
              </ListItem>
            ))}
          </List>
          {currentArray[index + 1] && <DividerStyled />}
        </ListItemBlockStyled>
      </List>
    );
  });
};

export default function PatchNotePopin() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDontDisplayInputChecked, setIsDontDisplayInputChecked] = useState(false);

  // TODO : check ressources pour la bonne gestion, des patch notes
  // TODO : save patch note en BDD

  const handleOpen = () => {
    const isPatchNoteViewed = sessionStorage.getItem("patch-note-20240111");
    const isDontDisplayChoice = localStorage.getItem("patch-note-20240111");

    if (!!isPatchNoteViewed || !!isDontDisplayChoice) return;
    setIsOpen(true);
  };

  useEffect(() => {
    handleOpen();
  }, []);

  const handleClose = () => {
    setIsOpen(false);

    if (isDontDisplayInputChecked) {
      localStorage.setItem("patch-note-20240111", true);
    } else {
      sessionStorage.setItem("patch-note-20240111", true);
    }
  };

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} keepMounted aria-labelledby="patch-note-popin__title" onClose={handleClose} fullWidth maxWidth={"sm"}>
      <DialogTitle id="patch-note-popin__title">
        Patch Notes
        <IconButtonStyled aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButtonStyled>
      </DialogTitle>
      <DialogContent dividers>{displayContent()}</DialogContent>
      <DialogContent style={{ padding: "10px 24px" }}>
        <FormControlLabel control={<Checkbox onChange={(e) => setIsDontDisplayInputChecked(e.target.checked)} />} label="Ne plus afficher" />
      </DialogContent>
    </Dialog>
  );
}
