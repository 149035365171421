import { useState, useLayoutEffect, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";

export default function ControlledTooltips({ title = "" }) {
  const tooltipRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [tooltipWidth, setTooltipWidth] = useState(0);

  useLayoutEffect(() => {
    setTooltipWidth({ currentWidth: tooltipRef.current.offsetWidth, wrapperWidth: tooltipRef.current.parentNode.offsetWidth - 20 });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip disableInteractive open={tooltipWidth.currentWidth >= tooltipWidth.wrapperWidth ? open : false} onClose={handleClose} onOpen={handleOpen} title={title} ref={tooltipRef}>
      <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{title}</span>
    </Tooltip>
  );
}
