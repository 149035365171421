import axios from "axios";
import { objectToQueryString } from "../utils/query";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getTypologies = async (params = {}) => {
  const accessToken = getAccessToken();
  try {
    const { offset = 0, limit = 0 } = params;
    const queryString = params ? objectToQueryString({ offset, limit }) : "";
    const { data: typologies } = await axios.get(`${process.env.REACT_APP_PROXY}/typologies${queryString}`, { headers: { Authorization: "Bearer " + accessToken } });
    return typologies;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeTypology = async (typologyId) => {
  const accessToken = getAccessToken();

  try {
    const { data: removedTypology } = await axios.delete(`${process.env.REACT_APP_PROXY}/typologies/${typologyId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedTypology;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addTypology = async (typology) => {
  const accessToken = getAccessToken();

  try {
    const { data: addedTypology } = await axios.post(
      `${process.env.REACT_APP_PROXY}/typologies`,
      {
        data: typology,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return addedTypology;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateTypology = async ({ id, ...typologyData }) => {
  const accessToken = getAccessToken();

  try {
    const { data: updatedTypology } = await axios.put(
      `${process.env.REACT_APP_PROXY}/typologies/${id}`,
      {
        data: typologyData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedTypology;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
