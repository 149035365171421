import { useState, useEffect, forwardRef, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SelectAutocomplete from "../Input/SelectAutocomplete";
import DatePicker from "../Input/DatePicker";
import InputCheckbox from "../Input/Checkbox";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ isOpen, action, handleClose, title, headCells, itemData = {}, deleteData }) {
  const [formData, setFormData] = useState({});
  const [formDataRequired, setFormDataRequired] = useState([]);
  const ref = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const requiredInputs = headCells.filter((cell) => cell.isRequired);

      setFormData({
        ...itemData,
        ...(itemData.canShare == null && { canShare: true }),
      });
      setFormDataRequired(requiredInputs);

      console.log("itemData", itemData, formData);
    } else {
      setFormData({});
    }
  }, [isOpen]);

  const onChangeSelectInput = (inputId, values) => {
    setFormData({ ...formData, [inputId]: values });
  };

  const onChangeInput = (inputId, { target: { value } }) => {
    setFormData({ ...formData, [inputId]: value });
  };

  const onChangeInputDate = (inputId, value) => {
    setFormData({ ...formData, [inputId]: value });
  };

  const onChangeCheckbox = (inputId, isChecked) => {
    setFormData({ ...formData, [inputId]: isChecked });
  };

  const getFormattedPreviewLink = (previewLink) => {
    if (!/https:\/\//g.test(previewLink)) {
      return "https://" + previewLink.replace(/\s/g, "");
    }
    return previewLink.replace(/\s/g, "");
  };

  const actionPopin = () => {
    const updatedRequiredInputData = formDataRequired.map((requiredData) => {
      const hasValue = formData[requiredData.field] && /\w+/g.test(formData[requiredData.field]);
      if (hasValue) {
        return { ...requiredData, hasError: false };
      } else {
        return { ...requiredData, hasError: true };
      }
    });
    const hasError = updatedRequiredInputData.find((requiredInput) => requiredInput.hasError);
    setFormDataRequired(updatedRequiredInputData);

    if (hasError) return;

    action(formData);
    handleClose();
  };

  const deleteItem = () => {
    deleteData(itemData.id);
    handleClose();
  };

  const formatInput = (inputId, { target: { value } }, b) => {
    if (inputId === "previewLink" && /\w+/g.test(value)) {
      const updatedLink = getFormattedPreviewLink(value);
      setFormData({ ...formData, [inputId]: updatedLink });
    }

    if (inputId === "price") {
      if (/^(\d+)$/g.test(value)) {
        setFormData({ ...formData, [inputId]: +value });
      } else {
        setFormData({ ...formData, [inputId]: null });
      }
    }
  };

  const displayFields = () => {
    return headCells.map((field) => {
      const hasFormError = !!formDataRequired.find((dataRequired) => dataRequired.field === field.field && dataRequired.hasError);

      if (field.type === "textField") {
        return (
          <TextField
            sx={{ display: "block", padding: "5px 0" }}
            fullWidth
            key={field.field}
            required={!!field.isRequired}
            value={formData[field.field] || ""}
            id={field.field}
            label={field.headerName}
            variant="standard"
            onChange={onChangeInput.bind(this, field.field)}
            error={hasFormError}
            onBlur={formatInput.bind(this, field.field)}
          />
        );
      } else if (field.type === "textFieldNumber") {
        console.log("field", field);
        return (
          <TextField
            sx={{ display: "block", padding: "5px 0" }}
            fullWidth
            key={field.field}
            required={!!field.isRequired}
            value={formData[field.field] || null}
            id={field.field}
            label={field.headerName}
            variant="standard"
            onChange={onChangeInput.bind(this, field.field)}
            error={hasFormError}
            onBlur={formatInput.bind(this, field.field)}
            type="number"
          />
        );
      } else if (field.type === "selectAutocomplete") {
        const { isSelectMultiple, displayedValueMax } = field;
        // const fieldValue = isSelectMultiple ? formData[field.field] : formData[field.field]?.[0] ?? null;
        return (
          <div style={{ padding: "5px 0" }}>
            <SelectAutocomplete key={field.field} id={field.field} label={field.headerName} onChange={onChangeSelectInput} fetchAction={field.fetchAction} fieldValue={formData[field.field]} isSelectMultiple={isSelectMultiple} displayedValueMax={displayedValueMax} />
          </div>
        );
      } else if (field.type === "dateTime") {
        return (
          <div style={{ padding: "5px 0" }}>
            <DatePicker key={field.field} id={field.field} onChange={onChangeInputDate} dateValue={formData[field.field]} />
          </div>
        );
      } else if (field.type === "checkbox") {
        const canShare = formData[field.field];
        console.log("canShare", canShare, canShare == null);
        return (
          <div style={{ padding: "5px 0" }}>
            <InputCheckbox sx={{ display: "block", padding: "5px 0" }} key={field.field} id={field.field} isInputChecked={canShare} onChange={onChangeCheckbox} label="Sharable" />
          </div>
        );
      }
    });
  };

  const displayDeleteButton = () => {
    if (Object.values(itemData).length > 0) {
      return (
        <Button variant="outlined" color="error" onClick={deleteItem}>
          Delete
        </Button>
      );
    }
  };

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" ref={ref}>
          {displayFields()}
        </Box>
      </DialogContent>
      <DialogActions>
        {displayDeleteButton()}
        <Button variant="outlined" onClick={handleClose} sx={{ color: "#282828" }}>
          Cancel
        </Button>
        <Button variant="contained" color="success" onClick={actionPopin}>
          Validate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
