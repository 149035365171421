import { getSales, removeSale, addSale, updateSale } from "../../services/sales";
import DataTable from "../Table/DataTable";
import { useAuth } from "../Hooks/use-auth";
import { useDispatch } from "react-redux";
import { displaySuccessFeedback, displayErrorFeedback } from "../../app/feedbackSlice";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.name,
  },
];

const tableName = "sales";

export default function SalesPages() {
  const { onLogout } = useAuth();
  const dispatch = useDispatch();

  const fetchData = async ({ offset, limit, filters }) => {
    try {
      const fetchedData = await getSales({ offset, limit, filters });
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const addData = async (dataToAdd) => {
    try {
      const fetchedData = await addSale(dataToAdd);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Sale successfully added." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const updateData = async (dataToUpdate) => {
    try {
      const fetchedData = await updateSale(dataToUpdate);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Sale successfully updated." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const deleteData = async (dataId) => {
    try {
      const fetchedData = await removeSale(dataId);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Sale successfully deleted." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  return <DataTable label={tableName} fetchData={fetchData} addData={addData} deleteData={deleteData} updateData={updateData} headCells={headCells} />;
}
