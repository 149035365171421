import { createSlice } from "@reduxjs/toolkit";

export const ALERT_VARIANTS = { success: "success", error: "error", warning: "warning", info: "info" };

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    isOpen: false,
    variant: ALERT_VARIANTS.success,
    message: "",
  },
  reducers: {
    displaySuccessFeedback: (state, { payload }) => {
      state.isOpen = true;
      state.variant = ALERT_VARIANTS.success;
      state.message = payload.message;
    },
    displayErrorFeedback: (state, { payload }) => {
      state.isOpen = true;
      state.variant = ALERT_VARIANTS.error;
      state.message = payload.message;
    },
    hideFeedback: (state) => {
      state.isOpen = false;
    },
  },
});

export const { displaySuccessFeedback, displayErrorFeedback, hideFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
