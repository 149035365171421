import { useState, useEffect, forwardRef } from "react";
import { TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import { Collapse, Dialog, DialogContent, DialogTitle, Slide, IconButton, Typography, AccordionActions, TextField, Chip, Accordion, AccordionDetails } from "@mui/material";
import {
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
  Business as BusinessIcon,
  DeveloperBoard as DeveloperBoardIcon,
  People as PeopleIcon,
  Hail as HailIcon,
  AccountTree as AccountTreeIcon,
  Celebration as CelebrationIcon,
  Loyalty as LoyaltyIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from "@mui/icons-material";
import { removeSearchById, getSearches, updateSearchTitle } from "../../services/searches";
import { IconButtonStyled } from "./style/Popin.styled";
import { TitleEditorContainer, SearchValidButtonContainer, FilterInfoContainer, FilterInfo, AccordionSummaryStyled } from "./style/PopinSavedSearch.styled";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SavedSearchFilters = ({ searchData: { clients, celebrations, sectors, sales, typologies, providers, projectName } }) => {
  const getList = (data) => data.map(({ name }) => name).join(", ");
  return (
    <>
      {!!projectName && (
        <FilterInfoContainer>
          <Chip size="small" icon={<AccountTreeIcon />} label="Project name" variant="outlined" />
          <FilterInfo>{projectName}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!clients.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<PeopleIcon />} label="Client" variant="outlined" />
          <FilterInfo>{getList(clients)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!sales.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<LoyaltyIcon />} label="Sales" variant="outlined" />
          <FilterInfo>{getList(sales)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!sectors.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<BusinessIcon />} label="Sectors" variant="outlined" />
          <FilterInfo>{getList(sectors)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!typologies.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<DeveloperBoardIcon />} label="Typologies" variant="outlined" />
          <FilterInfo>{getList(typologies)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!celebrations.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<CelebrationIcon />} label="Celebrations" variant="outlined" />
          <FilterInfo>{getList(celebrations)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!providers.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<HailIcon />} label="Providers" variant="outlined" />
          <FilterInfo>{getList(providers)}</FilterInfo>
        </FilterInfoContainer>
      )}
    </>
  );
};

SavedSearchFilters.propTypes = {
  searchData: PropTypes.object.isRequired,
};

const SavedSearchItem = ({ search, getFilteredSearch, deleteSavedSearch, expanded, handleChange, setFetchedSavedSearchList, fetchedSavedSearchList }) => {
  const { id, title } = search;
  const [searchTitle, setSearchTitle] = useState("");
  const [hasInputError, setHasInputError] = useState(false);
  const [isTitleEditorVisibile, setIsTitleEditorVisible] = useState(false);
  const [hasSuccessUpdate, setHasSuccessUpdate] = useState(false);

  const toggleTitleEditorVisibility = () => {
    setIsTitleEditorVisible(!isTitleEditorVisibile);
  };

  const handleInputChange = ({ target: { value } }) => {
    const isCorrect = /^[a-z ,.'-]+$/g.test(value);
    setHasInputError(!isCorrect);
    setSearchTitle(value);
  };

  const onClickValidInput = async () => {
    const { title: updatedSearchTitle, id: updatedSearchId } = await updateSearchTitle(id, searchTitle);
    const updatedSearchList = fetchedSavedSearchList.map((fetchedSearch) => {
      if (fetchedSearch.id === updatedSearchId) {
        return { ...fetchedSearch, title: updatedSearchTitle };
      }
      return fetchedSearch;
    });

    setFetchedSavedSearchList(updatedSearchList);
    setHasSuccessUpdate(true);
    setHasInputError(false);

    setTimeout(() => {
      setSearchTitle("");
      setIsTitleEditorVisible(false);
      setHasSuccessUpdate(false);
    }, 2000);
  };

  return (
    <>
      <Accordion expanded={expanded === String(id)} onChange={handleChange(String(id))} disableGutters>
        <AccordionSummaryStyled expanded={expanded ? String(id) : "undefined"} isopen={Number(expanded === id)} expandIcon={<ExpandMoreIcon />} aria-controls={"panel" + id + "bh-content"}>
          <Typography>{title}</Typography>
        </AccordionSummaryStyled>
        <AccordionDetails id={"panel" + id + "bh-content"}>
          <SavedSearchFilters searchData={search} />
        </AccordionDetails>
        <AccordionActions>
          <IconButton color="primary" aria-label="Search" component="span" onClick={getFilteredSearch.bind(null, search)}>
            <SearchIcon />
          </IconButton>
          <TitleEditorContainer isvisible={isTitleEditorVisibile}>
            <SearchValidButtonContainer isdisplayed={searchTitle.length} isvisible={isTitleEditorVisibile} hassuccess={hasSuccessUpdate}>
              <IconButton aria-label="Validate search name update" onClick={onClickValidInput}>
                <CheckCircleOutlineIcon color="success" />
              </IconButton>
              <Typography>Successfully updated!</Typography>
            </SearchValidButtonContainer>
            <TextField error={hasInputError} id="search-name-input" placeholder="Update search name" variant="standard" size="small" required value={searchTitle} onChange={handleInputChange} />
          </TitleEditorContainer>
          <IconButton color="primary" aria-label="Edit search" component="span" onClick={toggleTitleEditorVisibility}>
            <EditIcon />
          </IconButton>
          <IconButton color="primary" aria-label="Delete search" component="span" onClick={deleteSavedSearch.bind(null, search.id)}>
            <ClearIcon />
          </IconButton>
        </AccordionActions>
      </Accordion>
    </>
  );
};

SavedSearchItem.propTypes = {
  search: PropTypes.object.isRequired,
  getFilteredSearch: PropTypes.func.isRequired,
  deleteSavedSearch: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFetchedSavedSearchList: PropTypes.func.isRequired,
  fetchedSavedSearchList: PropTypes.array.isRequired,
};

const SavedSearchList = ({ fetchedSavedSearchList, getFilteredSearch, deleteSavedSearch, setFetchedSavedSearchList }) => {
  const [expanded, setExpanded] = useState("false");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(String(isExpanded && panel));
  };

  const getSavedSearchItemList = () => {
    return fetchedSavedSearchList.map((search, index) => {
      return (
        <Collapse key={index}>
          <SavedSearchItem expanded={expanded} setFetchedSavedSearchList={setFetchedSavedSearchList} fetchedSavedSearchList={fetchedSavedSearchList} search={search} getFilteredSearch={getFilteredSearch} deleteSavedSearch={deleteSavedSearch} handleChange={handleChange} />
        </Collapse>
      );
    });
  };

  return <TransitionGroup>{getSavedSearchItemList()}</TransitionGroup>;
};

SavedSearchList.propTypes = {
  fetchedSavedSearchList: PropTypes.array.isRequired,
  getFilteredSearch: PropTypes.func.isRequired,
  deleteSavedSearch: PropTypes.func.isRequired,
  setFetchedSavedSearchList: PropTypes.func.isRequired,
};

export default function SavedSearchPopin({ isOpen, handleClose, setFilters }) {
  // TODO : check pourquoi rerender list
  const [fetchedSavedSearchList, setFetchedSavedSearchList] = useState([]);

  const fetchData = async () => {
    const fetchedData = await getSearches();
    setFetchedSavedSearchList(fetchedData);
  };

  const getFilteredSearch = (selectedSearch) => {
    setFilters({ ...selectedSearch, name: selectedSearch.projectName });
    handleClose();
  };

  const deleteSavedSearch = async (searchId) => {
    const removedSearch = await removeSearchById(searchId);
    setFetchedSavedSearchList((prev) => [...prev.filter((savedSearch) => savedSearch.id !== removedSearch.id)]);
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="saved-search-popin__title" fullWidth={true} maxWidth={"sm"}>
      <DialogTitle id="saved-search-popin__title">
        My saved searches
        <IconButtonStyled aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButtonStyled>
      </DialogTitle>
      <DialogContent dividers>
        {!!fetchedSavedSearchList.length ? <SavedSearchList fetchedSavedSearchList={fetchedSavedSearchList} getFilteredSearch={getFilteredSearch} deleteSavedSearch={deleteSavedSearch} setFetchedSavedSearchList={setFetchedSavedSearchList} /> : <p>No saved search</p>}
      </DialogContent>
    </Dialog>
  );
}

SavedSearchPopin.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};
