import axios from "axios";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getCelebrations = async () => {
  const accessToken = getAccessToken();
  try {
    const { data: celebrations } = await axios.get(`${process.env.REACT_APP_PROXY}/celebrations`, { headers: { Authorization: "Bearer " + accessToken } });
    return celebrations;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeCelebration = async (celebrationId) => {
  const accessToken = getAccessToken();
  try {
    const { data: removedCelebration } = await axios.delete(`${process.env.REACT_APP_PROXY}/celebrations/${celebrationId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedCelebration;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addCelebration = async (celebration) => {
  const accessToken = getAccessToken();
  try {
    const { data: addedCelebration } = await axios.post(
      `${process.env.REACT_APP_PROXY}/celebrations`,
      {
        data: celebration,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return addedCelebration;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateCelebration = async ({ id, ...celebrationData }) => {
  const accessToken = getAccessToken();
  try {
    const { data: updatedCelebration } = await axios.put(
      `${process.env.REACT_APP_PROXY}/celebrations/${id}`,
      {
        data: celebrationData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedCelebration;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
