import { useState } from "react";
import { Box, TextField } from "@mui/material";
import { AccountCircle, Password as PasswordIcon } from "@mui/icons-material";
import { Container, SubContainer, Form, LoginButton, Title } from "./Login.styled";
import { useAuth } from "../Hooks/use-auth";
import { useDispatch } from "react-redux";
import { displayErrorFeedback } from "../../app/feedbackSlice";

export default function LoginPage() {
  const [loginData, setLoginData] = useState({});
  const { onLogin } = useAuth();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await onLogin(loginData);
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: e.message }));
    }
  };

  const handleChange = (dataName, { target: { value } }) => {
    setLoginData({ ...loginData, [dataName]: value });
  };

  return (
    <Container>
      <SubContainer className="login-container">
        <Title>
          <span className="digital" data-replace="Hello you!">
            <span>Digital Prod</span>
          </span>
          <span className="agence">agence</span>
        </Title>
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="username" label="Username" variant="standard" value={loginData.username} onChange={handleChange.bind(this, "username")} margin="dense" />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <PasswordIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
            <TextField id="password" label="Password" type="password" variant="standard" value={loginData.password} onChange={handleChange.bind(this, "password")} margin="dense" />
          </Box>
          <LoginButton type="submit" color="success">
            Login
          </LoginButton>
        </Form>
      </SubContainer>
    </Container>
  );
}
