import { getProjects, removeProject, addProject, updateProject } from "../../services/projects";
import { getClients } from "../../services/clients";
import { getCelebrations } from "../../services/celebrations";
import { getSectors } from "../../services/sectors";
import { getTypologies } from "../../services/typologies";
import { getSales } from "../../services/sales";
import { getProviders } from "../../services/providers";
import DataTable from "../Table/DataTable";
import Tooltip from "../Utils/Tooltip";
import { DeveloperBoard as DeveloperBoardIcon } from "@mui/icons-material";
import { useAuth } from "../Hooks/use-auth";
import PatchNotePopin from "../Popin/PatchNote";
import { useDispatch } from "react-redux";
import { displaySuccessFeedback, displayErrorFeedback } from "../../app/feedbackSlice";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    minWidth: 300,
    isOnFilter: true,
    valueGetter: (params) => params.row.name,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
    icon: () => <DeveloperBoardIcon />,
  },
  {
    field: "previewLink",
    headerName: "Preview link",
    sortable: false,
    editable: false,
    isOnTable: false,
    cellType: "actions",
    type: "textField",
    flex: 1,
    actionType: "link",
    isRequired: true,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "clients",
    headerName: "Client",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.clients?.map((c) => c.name).join(", "),
    fetchAction: async () => await getClients(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "sectors",
    headerName: "Sectors",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.sectors?.map((s) => s.name).join(", "),
    fetchAction: async () => await getSectors(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "typologies",
    headerName: "Typologies",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.typologies?.map((t) => t.name).join(", "),
    fetchAction: async () => await getTypologies(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "celebrations",
    headerName: "Celebration",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.celebrations?.map((c) => c.name).join(", "),
    fetchAction: async () => await getCelebrations(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "sales",
    headerName: "Sale",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    maxWidth: 150,
    isOnFilter: true,
    valueGetter: (params) => params.row.sales?.map((s) => s.name).join(", "),
    fetchAction: async () => await getSales(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "providers",
    headerName: "Providers",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    maxWidth: 150,
    isOnFilter: true,
    valueGetter: (params) => params.row.providers?.map((s) => s.name).join(", "),
    fetchAction: async () => await getProviders(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "date",
    headerName: "Date",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "dateTime",
    isOnFilter: true,
    valueGetter: (params) => {
      return params.row.date?.split("T")[0] || "";
    },
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "canShare",
    headerName: "Sharable",
    sortable: true,
    editable: false,
    isOnTable: false,
    type: "checkbox",
    isDefaultChecked: true,
    valueGetter: (params) => params.row.canShare,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
];

const tableName = "project";

export default function ProjectsPages() {
  const { onLogout } = useAuth();
  const dispatch = useDispatch();

  const fetchData = async ({ offset, limit, filters, sortModel }) => {
    try {
      const fetchedData = await getProjects({ offset, limit, filters, sortModel });
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const addData = async (dataToAdd) => {
    console.log("dataToAdd", dataToAdd);
    try {
      const fetchedData = await addProject(dataToAdd);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Project successfully added." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const updateData = async (dataToUpdate) => {
    try {
      const fetchedData = await updateProject(dataToUpdate);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Project successfully updated." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const deleteData = async (dataId) => {
    try {
      const fetchedData = await removeProject(dataId);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Project successfully deleted." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  return (
    <>
      <PatchNotePopin />
      <DataTable label={tableName} fetchData={fetchData} addData={addData} deleteData={deleteData} updateData={updateData} headCells={headCells} hasFilter={true} />;
    </>
  );
}
