import axios from "axios";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getProjects = async ({ offset, limit, filters = {}, sortModel }) => {
  const accessToken = getAccessToken();
  const filterList = Object.entries(filters)
    .reduce((acc, val) => {
      const key = val[0];
      const values = val[1];
      if (values?.length) {
        if (Array.isArray(values)) {
          const formattedValues = values.reduce(
            (acc, v) => {
              if (v.isTextSearch) {
                return { ...acc, [key + "Name"]: [...acc[key + "Name"], v.name] };
              }
              return { ...acc, [key]: [...acc[key], v.id] };
            },
            { [key]: [], [key + "Name"]: [] }
          );

          return [...acc, !!formattedValues[key]?.length ? `${[key]}=${formattedValues[key].join(",")}` : "", !!formattedValues[key + "Name"]?.length ? `${[key + "Name"]}=${formattedValues[key + "Name"].join(",")}` : ""];
        }
        return [...acc, `${key}=${values}`];
        // return
        // const formattedValue = Array.isArray(values) ? values.map((v) => v.id).join(",") : values;
        // return [...acc, `${key}=${formattedValue}`];
      }
      return acc;
    }, [])
    .join("&");

  const filterListUrl = filterList ? "&" + filterList : "";

  const sortModalParam = sortModel ? `&sortField=${sortModel.field}&sortDirection=${sortModel.sort}` : "";

  try {
    const { data: projects } = await axios.get(`${process.env.REACT_APP_PROXY}/projects?limit=${limit}&offset=${offset}${filterListUrl}${sortModalParam}`, { headers: { Authorization: "Bearer " + accessToken } });
    return projects;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeProject = async (projectId) => {
  const accessToken = getAccessToken();

  try {
    const { data: removedProject } = await axios.delete(`${process.env.REACT_APP_PROXY}/projects/${projectId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedProject;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addProject = async (project) => {
  const accessToken = getAccessToken();
  try {
    const { data: addedProjects } = await axios.post(`${process.env.REACT_APP_PROXY}/projects`, { data: project }, { headers: { Authorization: "Bearer " + accessToken } });
    return addedProjects;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateProject = async ({ id, ...projectData }) => {
  const accessToken = getAccessToken();

  try {
    const { data: updatedProject } = await axios.put(
      `${process.env.REACT_APP_PROXY}/projects/${id}`,
      {
        data: projectData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedProject;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
