import axios from "axios";
import { objectToQueryString } from "../utils/query";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getSales = async (params = {}) => {
  const accessToken = getAccessToken();
  try {
    const { offset = 0, limit = 0 } = params;
    const queryString = params ? objectToQueryString({ offset, limit }) : "";
    const { data: sales } = await axios.get(`${process.env.REACT_APP_PROXY}/sales${queryString}`, { headers: { Authorization: "Bearer " + accessToken } });
    return sales;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeSale = async (saleId) => {
  const accessToken = getAccessToken();

  try {
    const { data: removedSale } = await axios.delete(`${process.env.REACT_APP_PROXY}/sales/${saleId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedSale;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addSale = async (sale) => {
  const accessToken = getAccessToken();

  try {
    const { data: addedSale } = await axios.post(
      `${process.env.REACT_APP_PROXY}/sales`,
      {
        data: sale,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return addedSale;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateSale = async ({ id, ...saleData }) => {
  const accessToken = getAccessToken();

  try {
    const { data: updatedSale } = await axios.put(
      `${process.env.REACT_APP_PROXY}/sales/${id}`,
      {
        data: saleData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedSale;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
