import axios from "axios";

export const getUserById = async (userId) => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));
  try {
    const { data: user } = await axios.get(`${process.env.REACT_APP_PROXY}/users/${userId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return user;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const getUsers = async () => {
  const accessToken = JSON.parse(localStorage.getItem("dpapp_key"));
  try {
    const { data: user } = await axios.get(`${process.env.REACT_APP_PROXY}/users`, { headers: { Authorization: "Bearer " + accessToken } });
    return user;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
