import styled from "styled-components";
import { Typography, Divider, IconButton, ListItem } from "@mui/material";

export const DividerStyled = styled(Divider)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TypographyTitleStyled = styled(Typography)`
  font-weight: bold;
`;

export const TypographySubTitleStyled = styled(Typography)`
  font-weight: bold;
  font-size: 0.85em;
  opacity: 0.7;
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const ListItemBlockStyled = styled(ListItem)`
  display: block;
`;
