import axios from "axios";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getSearches = async () => {
  const accessToken = getAccessToken();
  try {
    const { data: searches } = await axios.get(`${process.env.REACT_APP_PROXY}/searches`, { headers: { Authorization: "Bearer " + accessToken } });
    return searches;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeSearchById = async (searchId) => {
  const accessToken = getAccessToken();
  try {
    const { data: removedSearch } = await axios.delete(`${process.env.REACT_APP_PROXY}/searches/${searchId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedSearch;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addSearch = async (search) => {
  const accessToken = getAccessToken();
  try {
    const { data: addedSearches } = await axios.post(`${process.env.REACT_APP_PROXY}/searches`, { data: search }, { headers: { Authorization: "Bearer " + accessToken } });
    return addedSearches;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateSearchTitle = async (searchId, searchTitle) => {
  const accessToken = getAccessToken();
  try {
    const { data: updatedSearch } = await axios.put(`${process.env.REACT_APP_PROXY}/searches/${searchId}`, { data: searchTitle }, { headers: { Authorization: "Bearer " + accessToken } });
    return updatedSearch;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
