import { getProviders, removeProvider, addProvider, updateProvider } from "../../services/providers";
import DataTable from "../Table/DataTable";
import { useAuth } from "../Hooks/use-auth";
import { useDispatch } from "react-redux";
import { displaySuccessFeedback, displayErrorFeedback } from "../../app/feedbackSlice";
import { getProvidersExpertise } from "../../services/providersExpertise";
import Tooltip from "../Utils/Tooltip";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.name,
  },
  {
    field: "expertiseList",
    headerName: "Providers' expertise",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.expertiseList?.map((s) => s.name).join(", "),
    fetchAction: async () => await getProvidersExpertise(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
    isTest: true,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.email,
    isTest: true,
  },
  {
    field: "phoneNumber",
    headerName: "Phone number",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.phoneNumber,
    isTest: true,
  },
  {
    field: "note",
    headerName: "Note",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.note,
    isTest: true,
  },
  {
    field: "price",
    headerName: "Price",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textFieldNumber",
    flex: 1,
    filterable: false,
    inputRegexValidator: /^(|\d+)$/gi,
    valueGetter: (params) => params.row.price,
    isTest: true,
  },
];

const tableName = "providers";

export default function ProvidersPages() {
  const { onLogout, getUserType } = useAuth();
  const accountType = getUserType();
  const dispatch = useDispatch();

  const fetchData = async ({ offset, limit, filters, sortModel }) => {
    try {
      const fetchedData = await getProviders({ offset, limit, filters, sortModel });
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const addData = async (dataToAdd) => {
    console.log("dataToAdd", dataToAdd);
    try {
      const fetchedData = await addProvider(dataToAdd);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Provider successfully added." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const updateData = async (dataToUpdate) => {
    try {
      const fetchedData = await updateProvider(dataToUpdate);
      console.log("fetchedData", fetchedData);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Provider successfully updated." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  const deleteData = async (dataId) => {
    try {
      const fetchedData = await removeProvider(dataId);
      if (fetchedData.error) {
        if (fetchedData.error.code === 401) {
          onLogout();
          dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
        } else {
          dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
        }
        return [];
      } else {
        dispatch(displaySuccessFeedback({ message: "Provider successfully deleted." }));
        return fetchedData;
      }
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
    }
  };

  return <DataTable label={tableName} fetchData={fetchData} addData={addData} deleteData={deleteData} updateData={updateData} headCells={headCells} hasFilter={accountType === "TEST"} />;
}
