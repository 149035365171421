import { useState, useEffect } from "react";
import { getPreviewLinkList } from "../../services/previews";
import { useLocation } from "react-router-dom";
import DigitalProdLogo from "../../images/digitalProd-logo.svg";
import { Email as EmailIcon, Launch as LaunchIcon } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { useAuth } from "../Hooks/use-auth";

export default function PreviewsPage() {
  const [data, setData] = useState([]);
  const { getUserType } = useAuth();
  const location = useLocation();
  const userType = getUserType();

  const getPreviewList = async () => {
    const searchProjectsParams = new URLSearchParams(location.search).get("projects");
    const previewLinkList = await getPreviewLinkList(searchProjectsParams);
    setData(previewLinkList);
  };

  useEffect(() => {
    getPreviewList();
  }, []);

  const displayLinkList = () => {
    return data.map(({ projectPreviewLink, projectName, clientName }) => {
      return (
        <Link href={projectPreviewLink} underline="none" rel="noopener" target="_blank" sx={{ width: "100%", display: "block" }}>
          <Button sx={{ color: "#fff", borderColor: "#fff", width: "100%" }} variant="outlined" endIcon={<LaunchIcon />}>
            {clientName} - {projectName}
          </Button>
        </Link>
      );
    });
  };

  const getBodyShare = () => {
    const body = data
      .map(({ clientName, projectName, projectPreviewLink }) => {
        return `${clientName} - ${projectName} \n${projectPreviewLink}`;
      })
      .join("\n\n");
    return encodeURIComponent(body);
  };

  return (
    <div style={{ boxSizing: "border-box", padding: "50px", backgroundColor: "#282828", width: "100%", height: "100%", overflowY: "scroll" }}>
      <img style={{ width: "300px", margin: "0 auto", display: "block" }} src={DigitalProdLogo} alt="Digital Prod Agence" />

      <Stack sx={{ maxWidth: "500px", width: "100%", margin: "0 auto", marginTop: "50px" }} direction="column" spacing={2}>
        {displayLinkList()}
      </Stack>

      {userType === "TEST" && !!userType && (
        <Stack sx={{ maxWidth: "500px", width: "100%", margin: "0 auto", marginTop: "50px", textAlign: "center" }} direction="row" spacing={2}>
          <Link href={`mailto:?subject=Digital Prod - Liens de preview&body=${getBodyShare()}`} underline="none" rel="noopener" target="_blank" sx={{ width: "100%", display: "block" }}>
            <Button sx={{ color: "#fff", borderColor: "#fff", width: "fit-content" }} variant="outlined" endIcon={<EmailIcon />}>
              Partager par mail
            </Button>
          </Link>
        </Stack>
      )}
    </div>
  );
}
