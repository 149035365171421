import { Link } from "react-router-dom";
import { styled as muiStyled } from "@mui/material/styles";
import { Drawer, ListItem } from "@mui/material";
import styled from "styled-components";

export const Container = muiStyled(Drawer)(() => ({
  width: 100,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: 100,
    boxSizing: "border-box",
    backgroundColor: "#282828",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowY: "auto",
  },
  "& .MuiListItemText-root": {
    color: "#fff",
    textAlign: "center",
  },
  "& .MuiListItemIcon-root": {
    color: "#fff",
    display: "block",
    textAlign: "center",
  },
  "& .MuiTypography-root": {
    fontSize: "0.8rem",
  },
  "& .MuiList-root": {
    padding: 0,
  },
  "& ::-webkit-scrollbar": {
    width: "5px",
  },
  "& ::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "5px",
  },
}));

export const ListItemStyled = muiStyled(ListItem)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const PageLink = styled(Link)`
  color: #000;
  text-decoration: none;
  transition: 0.3;
  & .MuiButtonBase-root.MuiListItem-root {
    background-color: ${({ isselected }) => (isselected === "true" ? "rgba(113, 113, 113, 0.1)" : "rgba(0, 0, 0, 0)")};
  }
  & .MuiTypography-root {
    font-weight: ${({ isselected }) => (isselected === "true" ? 700 : 400)};
  }
`;

export const Logo = styled.img`
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
`;
