import styled, { keyframes } from "styled-components";
import { Button } from "@mui/material";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // & .fade-enter {
  //   opacity: 0;
  // }
  // & .fade-enter-active {
  //   opacity: 1;
  //   transition: opacity 200ms;
  //   color: red;
  // }
  // & .fade-exit {
  //   opacity: 1;
  // }
  // & .fade-exit-active {
  //   opacity: 0;
  //   transition: opacity 200ms;
  // }
`;

export const SubContainer = styled.div`
  position: relative;
`;

export const Form = styled.form`
  background-color: #fff;
  padding: 50px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 15px 0px rgb(0 0 0 / 10%);
`;

export const LoginButton = styled(Button)`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 20px;
  color: #282828;
  &:hover {
    color: #888888;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  width: 100%;
  text-align: center;
  padding: 0 20px;
  line-height: 40px;
  & > span.agence {
    font-weight: 400;
    display: block;
    text-align: right;
    color: #000;
    transition: color 0.3s cubic-bezier(0.76, 0, 0.24, 1);
    padding-right: 10px;
  }
  & > span.digital {
    overflow: hidden;
    position: relative;
    display: inline-block;
  }

  & > span.digital::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 0;
  }
  & > span.digital::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(200%, 0, 0);
    transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
    color: #fff;
  }
  & > span.digital:hover::after {
    transform: translate3d(0, 0, 0);
  }
  & > span.digital span {
    display: inline-block;
    transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  }
  & > span.digital:hover span {
    transform: translate3d(-200%, 0, 0);
  }
  & > span.digital:hover + span {
    color: #fff;
  }
`;
