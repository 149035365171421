import axios from "axios";
import { objectToQueryString } from "../utils/query";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getClients = async (params = {}) => {
  const accessToken = getAccessToken();
  try {
    const { offset = 0, limit = 0 } = params;
    const queryString = params ? objectToQueryString({ offset, limit }) : "";
    const { data: clients } = await axios.get(`${process.env.REACT_APP_PROXY}/clients${queryString}`, { headers: { Authorization: "Bearer " + accessToken } });
    return clients;
  } catch (e) {
    console.error(e);
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeClient = async (clientId) => {
  const accessToken = getAccessToken();

  try {
    const { data: removedClient } = await axios.delete(`${process.env.REACT_APP_PROXY}/clients/${clientId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedClient;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addClient = async (client) => {
  const accessToken = getAccessToken();

  try {
    const { data: addedClient } = await axios.post(
      `${process.env.REACT_APP_PROXY}/clients`,
      {
        data: client,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return addedClient;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateClient = async ({ id, ...clientData }) => {
  const accessToken = getAccessToken();

  try {
    const { data: updatedClient } = await axios.put(
      `${process.env.REACT_APP_PROXY}/clients/${id}`,
      {
        data: clientData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedClient;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
