import React, { useState, useEffect, createContext, useContext, useMemo } from "react";
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import Box from "@mui/material/Box";
import AlertBandeau, { ALERT_VARIANTS } from "./components/Alert/AlertBandeau";
import { Routes, Route, Link, useLocation, Outlet, Navigate, useSearchParams } from "react-router-dom";
import ClientsPage from "./components/Pages/Clients";
import CelebrationsPage from "./components/Pages/Celebrations";
import ProjectsPage from "./components/Pages/Projects";
import SectorsPage from "./components/Pages/Sectors";
import SalesPage from "./components/Pages/Sales";
import TypologiesPage from "./components/Pages/Typologies";
import LoginPage from "./components/Pages/Login";
import PreviewsPage from "./components/Pages/Previews";
import ProvidersPage from "./components/Pages/Providers";
import ProvidersExpertisePage from "./components/Pages/ProvidersExpertise";
import { ProvideAuth, useAuth } from "./components/Hooks/use-auth";
import ErrorPage from "./components/Pages/404Error";
import useMediaQuery from "@mui/material/useMediaQuery";
/********************/
import DrawerLeft from "./components/UI/DrawerLeft";
import FeedbackBandeau from "./components/Alert/AlertBandeau";
import PatchNotePopin from "./components/Popin/PatchNote";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BackgroundApp, Container } from "./App.styled";

const queryClient = new QueryClient();

export default function App() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [alertData, setAlertData] = useState({ isOpen: false, variant: ALERT_VARIANTS.success, message: "" });
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ["Montserrat, sans-serif"].join(","),
        },
        palette: {
          primary: { main: "#282828" },
          // mode: prefersDarkMode ? "dark" : "light",
          // mode: "dark",
        },
      }),
    [prefersDarkMode]
  );

  const { isLogged, onLogout } = useAuth();

  const ProtectedRoute = ({ children }) => {
    if (!isLogged) {
      return <Navigate to="/" replace />;
    }

    return children;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BackgroundApp>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DrawerLeft />
          <Container component="main">
            {/* <ProvideAuth> */}
            <FeedbackBandeau />
            <Routes>
              <Route path="/previews" element={<PreviewsPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/projects"
                element={
                  <ProtectedRoute>
                    <ProjectsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/clients"
                element={
                  <ProtectedRoute>
                    <ClientsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/celebrations"
                element={
                  <ProtectedRoute>
                    <CelebrationsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sectors"
                element={
                  <ProtectedRoute>
                    <SectorsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sales"
                element={
                  <ProtectedRoute>
                    <SalesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/typologies"
                element={
                  <ProtectedRoute>
                    <TypologiesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/providers"
                element={
                  <ProtectedRoute>
                    <ProvidersPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/providers-expertise"
                element={
                  <ProtectedRoute>
                    <ProvidersExpertisePage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<ErrorPage />}></Route>
            </Routes>
            {/* </ProvideAuth> */}
          </Container>
        </ThemeProvider>
        {/* </Box> */}
      </BackgroundApp>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
  );
}
