import { createTheme, styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import LoginBackground from "./images/login-background.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";

// export const theme = createTheme({
//   palette: { primary: { main: "#282828" }, mode: "dark" },
//   typography: {
//     fontFamily: ["Montserrat, sans-serif"].join(","),
//   },
// });

export const BackgroundApp = styled(Box)({
  backgroundImage: `url(${LoginBackground})`,
  height: "100vh",
  boxSizing: "border-box",
  // padding: 50,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  "& * ": {
    boxSizing: "border-box",
  },
});

export const Container = styled(Box)({
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
});
