import { useState, useEffect, useContext, createContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { signIn } from "../../services/auth";
import { getUserById } from "../../services/users";
import { useDispatch } from "react-redux";
import { displaySuccessFeedback, displayErrorFeedback } from "../../app/feedbackSlice";
import CryptoJS from "crypto-js";

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export function useProviderAuth() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async (loginData) => {
    const salt = process.env.BDD_SALT;
    const encryptedPassword = CryptoJS.SHA256(loginData.password + salt);
    const encryptedPasswordHex = encryptedPassword.toString(CryptoJS.enc.Hex);

    const { token, user, message, isError } = await signIn({ ...loginData, password: encryptedPasswordHex });
    if (!isError) {
      localStorage.setItem("dpapp_key", JSON.stringify(token));
      localStorage.setItem("dpapp_user_id", user.id);
      localStorage.setItem("dpapp_user_role", user.role);
      setIsLogged(true);
      navigate("/projects");
      dispatch(displaySuccessFeedback({ message: "You have successfully logged in !" }));
      return { isLogged: true };
    } else {
      localStorage.removeItem("dpapp_key");
      localStorage.removeItem("dpapp_user_id");
      localStorage.removeItem("dpapp_user_role");
      setIsLogged(false);
      dispatch(displayErrorFeedback({ message: "Oops, an error occurred..." }));
      throw new Error(message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("dpapp_key");
    localStorage.removeItem("dpapp_user_id");
    localStorage.removeItem("dpapp_user_role");
    setIsLogged(false);
    navigate("/login");
    dispatch(displayErrorFeedback({ message: "You have been disconnected." }));
  };

  const fetchUser = async () => {
    const userId = localStorage.getItem("dpapp_user_id");
    if (userId) {
      try {
        const fetchedUser = await getUserById(userId);
        if (fetchedUser.name) {
          setIsLogged(true);
          if (!/previews/g.test(location.pathname)) {
            navigate("/projects");
          }
        } else {
          handleLogout();
        }
      } catch (e) {
        console.error(e);
        handleLogout();
      }
    }
  };

  const getUserType = () => {
    const userRole = localStorage.getItem("dpapp_user_role");
    if (userRole === "undefined") handleLogout();
    return userRole;
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return {
    isLogged,
    getUserType,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProviderAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
