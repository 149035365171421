import { useState, useEffect, useMemo } from "react";
import EditIcon from "@mui/icons-material/Edit";
import PopinForm from "../Popin/PopinForm";
import { ContainerDataGrid } from "./styles/DataTable.styled";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Filters from "./Filters";
import { Button, IconButton } from "@mui/material";
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityIcon, AddBox as AddBoxIcon, ListAlt as ListAltIcon } from "@mui/icons-material";
import { getHashedIds } from "../../services/previews";
import SavedSearchPopin from "../Popin/PopinSavedSearch";
import { Search as SearchIcon } from "@mui/icons-material";
import { SearchOffOutlined as SearchOffOutlinedIcon, SavedSearchOutlined as SavedSearchOutlinedIcon, PushPinOutlined as PushPinOutlinedIcon } from "@mui/icons-material";
import { addSearch } from "../../services/searches.js";
import { useAuth } from "../Hooks/use-auth";
// import DataTableInnerList from "./DataTableInnerList";
import { StyledDataGrid } from "./styles/DataTable.styled";
import { useGridApiRef } from "@mui/x-data-grid";
// import { useFeedbackProvider } from "../Hooks/use-action-feedback";
const AddButton = ({ openPopinAdd }) => {
  return (
    <Button startIcon={<AddBoxIcon />} onClick={openPopinAdd}>
      Add
    </Button>
  );
};

const SearchButton = ({ getData, handleRowsState }) => {
  const fetchData = () => {
    handleRowsState("page", 0);
    getData();
  };

  return (
    <Button onClick={fetchData} startIcon={<SearchIcon />}>
      Search
    </Button>
  );
};

const ResetSearchButton = ({ resetSearch }) => {
  return (
    <Button onClick={resetSearch} startIcon={<SearchOffOutlinedIcon />}>
      Reset search
    </Button>
  );
};

const FavoriteSearchButton = ({ handleSavedSearchesPopin }) => {
  const { getUserType } = useAuth();
  const userType = getUserType();

  if (userType === "EXTERN") {
    return null;
  }

  return (
    <Button onClick={handleSavedSearchesPopin.bind(null, true)} startIcon={<SavedSearchOutlinedIcon />}>
      Saved Searches
    </Button>
  );
};

const RegisterSearchButton = ({ saveSearch }) => {
  const { getUserType } = useAuth();
  const userType = getUserType();
  if (userType === "EXTERN") {
    return null;
  }

  return (
    <Button onClick={saveSearch} startIcon={<PushPinOutlinedIcon />}>
      Save current search
    </Button>
  );
};

const ExportLinkButton = ({ selectionModel, redirectToPreviewsPage }) => {
  const { getUserType } = useAuth();
  const userType = getUserType();
  if (userType === "EXTERN") {
    return null;
  }

  return (
    !!selectionModel.length && (
      <Button startIcon={<ListAltIcon />} onClick={redirectToPreviewsPage}>
        Export links
      </Button>
    )
  );
};

export default function EnhancedTable({ hasFilter, headCells, label, fetchData, addData: addToList, deleteData: deleteFromList, updateData: updateList, setPopinAlert }) {
  const [popin, setPopin] = useState({ isOpen: false, action: null, title: null });
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 25,
  });
  const [rowCount, setRowCount] = useState(0);
  const [sortModel, setSortModel] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [filters, setFilters] = useState({});
  const [isSavedSearch, setIsSavedSearch] = useState(false);
  const [isOpenPopinSearch, setIsOpenPopinSearch] = useState(false);
  const { getUserType } = useAuth();
  const apiRef = useGridApiRef();
  // const { showAlert, variant } = useFeedbackProvider();

  console.log("headCells", headCells);
  const userType = getUserType();
  console.log("userType", userType);

  const headCellList = headCells.filter((cell) => !cell.isTest || (userType === "TEST" && cell.isTest));

  const handleFiltersList = (newFilters) => {
    setFilters((prev) => ({ ...prev, ...newFilters }));
  };

  const handleRowsState = (id, value) => {
    setRowsState((prev) => ({ ...prev, [id]: value }));
  };

  const getData = async () => {
    setIsLoading(true);
    const fetchedData = await fetchData({ offset: rowsState.page, limit: rowsState.pageSize, filters, sortModel });
    setData(fetchedData);
    setRowCount(+fetchedData[0]?.fullCount || 0);
    setIsLoading(false);
  };

  const addData = async (newData) => {
    const fetchedData = await addToList(newData);
    setData((prev) => [fetchedData, ...prev]);
  };

  const deleteData = async (dataId) => {
    const fetchedData = await deleteFromList(dataId);
    const deletedDataIndex = data.indexOf(data.find(({ id }) => id === fetchedData.id));
    setData([...data.slice(0, deletedDataIndex), ...data.slice(deletedDataIndex + 1)]);
  };

  const updateData = async (newData) => {
    const fetchedData = await updateList(newData);
    setData((prevData) => prevData.map((item) => (item.id === fetchedData.id ? fetchedData : item)));
  };

  const handleSavedSearchesPopin = (isOpen) => {
    setIsOpenPopinSearch(isOpen);
    // setAnchorEl(null);
  };

  const saveSearch = async () => {
    const savedSearch = await addSearch(filters);
    setIsSavedSearch(true);
  };

  const resetSearch = () => {
    setFilters({});
    getData();
  };

  useEffect(() => {
    getData();
  }, [rowsState.page, rowsState.pageSize]);

  const openPopinAdd = () => {
    console.log("headCells", headCells);
    const itemData = headCellList.reduce((acc, val) => {
      return { ...acc, [val.field]: val.isSelectMultiple ? [] : null };
    }, {});
    setPopin({ isOpen: true, action: async (newData) => await addData(newData), title: `Add ${label}`, itemData });
  };

  const openPopinUpdate = (row) => {
    console.log("openpopinupdate", row);
    setPopin({ isOpen: true, action: async (newData) => await updateData(newData), title: `Update ${label}`, itemData: row });
  };

  const getCustomActionCells = (row) => {
    const availableEditItem = <GridActionsCellItem icon={<EditIcon />} onClick={openPopinUpdate.bind(this, row)} label={`Edit ${label}`} />;
    const unavailableEditItem = <GridActionsCellItem disabled icon={<EditIcon />} label={`Edition unavailable`} />;
    const editItem = userType === "EXTERN" && row.creatorAccountType !== "EXTERN" ? unavailableEditItem : availableEditItem;

    const customActionCells = headCellList.filter((cell) => cell.cellType === "actions");

    const visibilityItem = customActionCells.reduce((acc, cell) => {
      if (cell.actionType === "link") {
        if (!!row.previewLink) {
          if (row.canShare) {
            return [
              ...acc,
              <a key={cell.field} style={{ color: "#000" }} aria-label="Sharable preview" href={row.previewLink} rel="noreferrer" target="_blank">
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
              </a>,
            ];
          } else {
            return [
              ...acc,
              <a key={cell.field} style={{ color: "#000" }} href={row.previewLink} rel="noreferrer" target="_blank">
                <IconButton disabled aria-label="Unsharable preview">
                  <VisibilityOffIcon />
                </IconButton>
              </a>,
            ];
          }
        } else {
          return acc;
        }
      } else {
        return acc;
      }
    }, []);

    return [editItem, ...visibilityItem];
  };

  const actionCells = {
    field: "actions",
    type: "actions",
    sortable: false,
    editable: false,
    isOnTable: true,
    width: 80,
    hideable: false,
    getActions: (params) => getCustomActionCells(params.row),
  };

  const dataToDisplay = [...headCellList.filter((cell) => cell.isOnTable), actionCells];
  const options = {
    checkboxSelection: false,
    isRowSelectable: () => false,
    loading: true,
    classes: ["virtualScroller"],
  };

  const onCellClick = (cell) => {
    if (cell.field === "edit") {
      openPopinUpdate(cell.row);
    }
  };

  const redirectToPreviewsPage = async () => {
    const hashedIds = await getHashedIds(selectionModel.join(","));
    if (!hashedIds) return;

    const newPage = window.open("/previews?projects=" + hashedIds, "_blank");
    newPage.focus();
    handleSelectionModel([]);
  };

  const handleSelectionModel = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);
  };

  const handleSortModelChange = async (sortModelData) => {
    if (sortModelData[0]?.field) {
      setSortModel(sortModelData[0]);
    } else {
      setSortModel(null);
    }
    getData();
  };

  const CustomToolbar = () => {
    const { dateTo, dateFrom, ...filtersProperties } = filters;
    const hasActiveFilter = !!Object.keys(filtersProperties).length;
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 10px" }}>
        <div>
          <AddButton openPopinAdd={openPopinAdd} />
          {hasFilter && <ExportLinkButton selectionModel={selectionModel} redirectToPreviewsPage={redirectToPreviewsPage} />}
        </div>
        <div>
          {hasFilter && <SearchButton getData={getData} handleRowsState={handleRowsState} />}
          {hasFilter && hasActiveFilter && <ResetSearchButton resetSearch={resetSearch} />}
          {hasFilter && hasActiveFilter && label === "project" && <RegisterSearchButton saveSearch={saveSearch} />}
          {hasFilter && label === "project" && <FavoriteSearchButton handleSavedSearchesPopin={handleSavedSearchesPopin} />}
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: "20px", width: "100%", height: "100%", display: "flex" }}>
      <ContainerDataGrid style={{ display: "flex", height: "100%", width: "100%" }}>
        {label === "project" && <SavedSearchPopin isOpen={isOpenPopinSearch} handleClose={handleSavedSearchesPopin.bind(null, false)} setFilters={setFilters} />}
        <PopinForm headCells={headCellList} isOpen={popin.isOpen} title={popin.title} action={popin.action} handleClose={() => setPopin({ ...popin, isOpen: false })} itemData={popin.itemData} deleteData={deleteData} />
        <div style={{ flexGrow: 1, width: "100%", display: "flex", flexDirection: "column" }}>
          {hasFilter && <Filters headCells={headCellList} getData={getData} setFilters={setFilters} filters={filters} handleFiltersList={handleFiltersList} />}
          <CustomToolbar />
          <StyledDataGrid
            apiRef={apiRef}
            onPageSizeChange={(newPageSize) => handleRowsState("pageSize", newPageSize)}
            onPageChange={(newPage) => handleRowsState("page", newPage)}
            onCellClick={onCellClick}
            onSelectionModelChange={handleSelectionModel}
            selectionModel={selectionModel}
            columns={dataToDisplay}
            rows={data}
            page={rowsState.page}
            pageSize={rowsState.pageSize}
            componentsProps={{
              columnMenu: { color: "primary" },
            }}
            options={options}
            loading={isLoading}
            rowCount={rowCount}
            paginationMode="server"
            sortingMode="server"
            rowsPerPageOptions={[25, 50, 100]}
            disableColumnFilter
            disableVirtualization
            getRowId={(row) => row.id}
            checkboxSelection={label === "project"}
            isRowSelectable={({ row }) => row.canShare && !!row.previewLink}
            onSortModelChange={handleSortModelChange}
            keepNonExistentRowsSelected
          />
        </div>
      </ContainerDataGrid>
    </div>
  );
}
