import { styled as muiStyled } from "@mui/material/styles";
import { Box, Popover, Badge, IconButton, Button } from "@mui/material";

export const Container = muiStyled("div")(() => ({
  "&.MuiModal-root.MuiDialog-root": {
    zIndex: 1301,
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
    color: "#282828",
  },
  "& .MuiAutocomplete-popped": {
    zIndex: 1302,
  },
  padding: "4px 5px",
  width: "100%",
}));

export const IconButtonStyled = muiStyled(IconButton)(() => ({
  fontSize: "0.875rem",
  lineHeight: "1.43",
  color: "#282828",
  textTransform: "uppercase",
  fontWeight: 500,
  minWidth: "64px",
  borderRadius: "4px",
}));

export const ContainerPopover = muiStyled(Box)(() => ({
  "& .MuiPaper-root.MuiPopover-paper": {
    padding: "10px",
  },
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  flexWrap: "wrap",
  gap: "10px",
}));

export const StyledBadge = muiStyled(Badge)(({ theme, nbBadge = 0 }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#282828",
    transition: "backgroundColor 0.3s",
  },
}));

// export const FieldContainer = muiStyled(Box)(() => ({
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
// }));

export const ContainerButton = muiStyled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "flex-end",
}));

export const SeeMoreFiltersButton = muiStyled(Button)(({ isDisplayed }) => ({}));
