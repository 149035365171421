export const objectToQueryString = (obj) => {
  const queryString = Object.entries(obj).reduce((accumulator, [key, value]) => {
    if (value === null || value === undefined) {
      return accumulator;
    }

    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    const keyValueString = `${encodedKey}=${encodedValue}`;

    return accumulator ? `${accumulator}&${keyValueString}` : keyValueString;
  }, "");

  return queryString ? `?${queryString}` : "";
};
