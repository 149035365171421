import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { AutocompleteStyled } from "./style/SelectAutocomplete.styled";

export default function Sizes({ id, label, onChange, fieldValue = [], fetchAction, autoWidthOnFocus, variant = "standard", handleFiltersList }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const fetchedData = await fetchAction();
    setOptions(fetchedData);
    setIsLoading(false);
  };

  useEffect(() => {
    isOpen && fetchData();
  }, [isOpen]);

  const toggleInput = () => {
    setIsOpen(!isOpen);
  };

  const onChangeInput = (event, values) => {
    const formattedValues = values.map((val) => {
      if (typeof val === "string") {
        return { id: "null", name: val, isTextSearch: true };
      }
      return val;
    });
    onChange(id, formattedValues);
  };

  return (
    <Stack spacing={2} className="table__filter__input">
      <AutocompleteStyled
        multiple={true}
        value={fieldValue}
        onChange={onChangeInput}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        id="size-small-standard-multi"
        size="small"
        options={options}
        open={isOpen}
        onOpen={toggleInput}
        onClose={toggleInput}
        limitTags={1}
        rows={1}
        freeSolo
        selectOnFocus
        clearOnBlur
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant={variant}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Stack>
  );
}
