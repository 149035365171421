import axios from "axios";
import { objectToQueryString } from "../utils/query";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getSectors = async (params = {}) => {
  const accessToken = getAccessToken();
  try {
    const { offset = 0, limit = 0 } = params;
    const queryString = params ? objectToQueryString({ offset, limit }) : "";
    const { data: sectors } = await axios.get(`${process.env.REACT_APP_PROXY}/sectors${queryString}`, { headers: { Authorization: "Bearer " + accessToken } });
    return sectors;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeSector = async (sectorId) => {
  const accessToken = getAccessToken();

  try {
    const { data: removedSector } = await axios.delete(`${process.env.REACT_APP_PROXY}/sectors/${sectorId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedSector;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addSector = async (sector) => {
  const accessToken = getAccessToken();

  try {
    const { data: addedSector } = await axios.post(
      `${process.env.REACT_APP_PROXY}/sectors`,
      {
        data: sector,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return addedSector;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateSector = async ({ id, ...sectorData }) => {
  const accessToken = getAccessToken();

  try {
    const { data: updatedSector } = await axios.put(
      `${process.env.REACT_APP_PROXY}/sectors/${id}`,
      {
        data: sectorData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedSector;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
