import { useState, useCallback } from "react";
import { TextField } from "@mui/material";
import SelectAutocomplete from "../Input/SelectAutocomplete";
import { ContainerPopover, SeeMoreFiltersButton } from "./styles/Filters.styled";
import CustomDatePicker from "../Input/DatePicker";
import { debounce } from "lodash";
import SelectAutocompleteFilterClient from "../Input/SelectAutocompleteFilterClient";
import "./styles/filters.css";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";

const FieldTextInput = ({ handleFiltersList, value = "" }) => {
  const [inputValue, setInputValue] = useState("");

  const debouncedHandleFiltersList = useCallback(
    debounce((name, value) => handleFiltersList({ [name]: value }), 300),
    [handleFiltersList]
  );

  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
    debouncedHandleFiltersList("name", value);
  };

  return <TextField size="small" className="table__filter__input" variant="outlined" id="filters-project-name-input" key={"project-name"} label="Project name" value={inputValue} onChange={handleInputChange} />;
};

export default function BasicPopover({ headCells, setFilters, filters, handleFiltersList }) {
  const [isDisplayingAllInputs, setIsDisplayingAllInputs] = useState(false);

  const setFilterList = (id, value) => {
    if (value.length) {
      setFilters({ ...filters, [id]: value });
    } else {
      const newFilter = Object.entries(filters).reduce((acc, filter) => {
        const key = filter[0];
        const filterValue = filter[1];
        if (key !== id) {
          return { ...acc, [key]: filterValue };
        }
        return acc;
      }, {});
      setFilters(newFilter);
    }
  };

  const handleChange = (id, value) => {
    setFilterList(id, value);
  };

  const handleProjectDate = (id, value) => {
    setFilters({ ...filters, [id]: value });
  };

  const displaySelectFields = () => {
    const filterListFields = headCells.filter(({ isOnFilter }) => isOnFilter);
    const filterList = isDisplayingAllInputs ? filterListFields : filterListFields.slice(0, 5);
    return filterList.reduce((acc, field) => {
      if (field.type === "textField") {
        return [...acc, <FieldTextInput handleFiltersList={handleFiltersList} value={filters[field.field]} />];
      }

      if (field.type === "selectAutocomplete") {
        if (field.field === "clients") {
          return [
            ...acc,
            <div className="table__filter__input">
              <SelectAutocompleteFilterClient variant="outlined" id={field.field} limitTags={1} label={field.headerName} onChange={handleChange} fetchAction={field.fetchAction} fieldValue={filters[field.field]} handleFiltersList={handleFiltersList}></SelectAutocompleteFilterClient>
            </div>,
          ];
        }
        return [
          ...acc,
          <div className="table__filter__input">
            <SelectAutocomplete variant="outlined" id={field.field} limitTags={1} label={field.headerName} onChange={handleChange} fetchAction={field.fetchAction} fieldValue={filters[field.field]}></SelectAutocomplete>
          </div>,
        ];
      }
      if (field.type === "dateTime") {
        return [
          ...acc,
          <div className="table__filter__input">
            <CustomDatePicker variant="outlined" key={field.field + "-from"} label="Date from" id={field.field + "From"} onChange={handleProjectDate} dateValue={null} />{" "}
          </div>,
          <div className="table__filter__input">
            <CustomDatePicker variant="outlined" key={field.field + "-to"} label="Date to" id={field.field + "To"} onChange={handleProjectDate} dateValue={null} />
          </div>,
        ];
      }
      return acc;
    }, []);
  };

  const onClickSeeMoreFiltersButton = () => {
    setIsDisplayingAllInputs((prev) => !prev);
  };

  const displaySeeMoreFiltersButton = () => {
    const filterListFields = headCells.filter(({ isOnFilter }) => isOnFilter);
    return (
      filterListFields.length > 5 && (
        <SeeMoreFiltersButton isDisplayed={!isDisplayingAllInputs} variant="outlined" startIcon={isDisplayingAllInputs ? <RemoveIcon /> : <AddIcon />} onClick={onClickSeeMoreFiltersButton}>
          {isDisplayingAllInputs ? "Less filters" : "More filters"}
        </SeeMoreFiltersButton>
      )
    );
  };

  return (
    <div className="container-filters">
      <ContainerPopover sx={{ borderColor: "primary.main" }} id={"filters"}>
        {displaySelectFields()}
        {displaySeeMoreFiltersButton()}
      </ContainerPopover>
    </div>
  );
}
