import { styled as muiStyled } from "@mui/material/styles";
import { Stack, Alert } from "@mui/material";

export const AlertContainer = muiStyled(Stack)(() => ({
  position: "fixed",
  left: "110px",
  width: "calc(100% - 120px)",
  boxShadow: "0 0 10px 0 rgba(0,0,0,0.3)",
  bottom: "16px",
  zIndex: "10000",
}));

export const AlertStyled = muiStyled(Alert)(() => ({
  mb: 2,
  marginBottom: 0,
}));
