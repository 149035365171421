import axios from "axios";
import { objectToQueryString } from "../utils/query";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getProvidersExpertise = async (params = {}) => {
  const accessToken = getAccessToken();
  try {
    const { offset = 0, limit = 0 } = params;
    const queryString = params ? objectToQueryString({ offset, limit }) : "";
    const { data: providersExpertise } = await axios.get(`${process.env.REACT_APP_PROXY}/providers-expertise${queryString}`, { headers: { Authorization: "Bearer " + accessToken } });
    return providersExpertise;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeProviderExpertise = async (providerExpertiseId) => {
  const accessToken = getAccessToken();

  try {
    const { data: removedProviderExpertise } = await axios.delete(`${process.env.REACT_APP_PROXY}/providers-expertise/${providerExpertiseId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedProviderExpertise;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addProviderExpertise = async (providerExpertise) => {
  const accessToken = getAccessToken();

  try {
    const { data: addedProviderExpertise } = await axios.post(
      `${process.env.REACT_APP_PROXY}/providers-expertise`,
      {
        data: providerExpertise,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    console.log("addedProviderExpertise", addedProviderExpertise);
    return addedProviderExpertise;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateProviderExpertise = async ({ id, ...providerExpertiseData }) => {
  const accessToken = getAccessToken();

  try {
    const { data: updatedProviderExpertise } = await axios.put(
      `${process.env.REACT_APP_PROXY}/providers-expertise/${id}`,
      {
        data: providerExpertiseData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedProviderExpertise;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
