import axios from "axios";
import { objectToQueryString } from "../utils/query";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

export const getProviders = async (params = {}) => {
  const { offset = 0, limit = 25, filters = {}, sortModel } = params;
  try {
    const accessToken = getAccessToken();
    const filterList = Object.entries(filters)
      .reduce((acc, val) => {
        const key = val[0];
        const values = val[1];
        console.log("acc", acc, key, values);

        if (values?.length) {
          if (Array.isArray(values)) {
            const formattedValues = values.reduce(
              (acc, v) => {
                return { ...acc, [key]: [...acc[key], v.id] };
              },
              { [key]: [], [key + "Name"]: [] }
            );

            return [...acc, !!formattedValues[key]?.length ? `${[key]}=${formattedValues[key].join(",")}` : "", !!formattedValues[key + "Name"]?.length ? `${[key + "Name"]}=${formattedValues[key + "Name"].join(",")}` : ""];
          }
          return [...acc, `${key}=${values}`];
        }
        return acc;
      }, [])
      .join("&");

    console.log("icidss", filterList);

    const filterListUrl = filterList ? "&" + filterList : "";
    const sortModalParam = sortModel ? `&sortField=${sortModel.field}&sortDirection=${sortModel.sort}` : "";
    const { data: providers } = await axios.get(`${process.env.REACT_APP_PROXY}/providers?limit=${limit}&offset=${offset}${filterListUrl}${sortModalParam}`, { headers: { Authorization: "Bearer " + accessToken } });
    return providers;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const removeProvider = async (providerId) => {
  const accessToken = getAccessToken();

  try {
    const { data: removedProvider } = await axios.delete(`${process.env.REACT_APP_PROXY}/providers/${providerId}`, { headers: { Authorization: "Bearer " + accessToken } });
    return removedProvider;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const addProvider = async (provider) => {
  const accessToken = getAccessToken();
  console.log("add", provider);
  try {
    const { data: addedProvider } = await axios.post(
      `${process.env.REACT_APP_PROXY}/providers`,
      {
        data: provider,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return addedProvider;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const updateProvider = async ({ id, ...providerData }) => {
  const accessToken = getAccessToken();
  console.log("ici", { id, ...providerData });
  try {
    const { data: updatedProvider } = await axios.put(
      `${process.env.REACT_APP_PROXY}/providers/${id}`,
      {
        data: providerData,
      },
      { headers: { Authorization: "Bearer " + accessToken } }
    );
    return updatedProvider;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
