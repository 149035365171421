import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function BasicDatePicker({ id, onChange, dateValue, size = "small", variant = "standard", views = ["year", "month", "day"], label = "Date", autoWidthOnFocus }) {
  const [value, setValue] = useState(dateValue);

  useEffect(() => {
    onChange(id, value);
  }, [value]);

  const handleChange = (newValue) => {
    if (!newValue) return;
    const date = new Date(newValue.$d).toLocaleDateString("en-gb");
    const splittedDate = date.split("/");
    const formattedValue = splittedDate[2] + "-" + splittedDate[1] + "-" + splittedDate[0] + "T00:00:00.000Z";
    setValue(formattedValue);
  };

  const onClear = () => {
    setValue(null);
  };

  return (
    <div className=".table__filter__input--full-width">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          minDate={"01-01-2013"}
          maxDate={new Date()}
          label={label}
          views={views}
          value={value}
          onChange={handleChange}
          renderInput={(params) => (
            <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
              <TextField {...params} size={size} variant={variant} sx={{ width: "100%" }} />
              <IconButton style={{ position: "absolute", top: ".5rem", margin: "auto", right: "2rem", padding: 0 }} onClick={() => onClear()}>
                <ClearIcon />
              </IconButton>
            </div>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
