import React, { useState, useEffect } from "react";
import { Divider, List, ListItemIcon, ListItemText } from "@mui/material";
import { Business as BusinessIcon, DeveloperBoard as DeveloperBoardIcon, People as PeopleIcon, Hail as HailIcon, PermMedia as PermMediaIcon, Celebration as CelebrationIcon, Loyalty as LoyaltyIcon, Logout as LogoutIcon, ThumbUpAlt as ThumbUpIcon } from "@mui/icons-material";
import { PageLink, Container, Logo, ListItemStyled } from "./DrawerLeft.styled";
import { useAuth } from "../Hooks/use-auth";
import DigitalProdLogo from "../../images/digitalProd-logo.svg";
import { useLocation } from "react-router-dom";

export const views = [
  { name: "projects", menuName: "Projects", pathName: "/projects", menuIcon: () => <PermMediaIcon />, authorizedAccounts: ["ADMIN", "CDP", "EXTERN", "TEST"] },
  {
    name: "clients",
    menuName: "Clients",
    pathName: "/clients",
    menuIcon: () => <PeopleIcon />,
    authorizedAccounts: ["ADMIN", "TEST"],
  },
  {
    name: "sales",
    menuName: "Sales",
    pathName: "/sales",
    menuIcon: () => <LoyaltyIcon />,
    authorizedAccounts: ["ADMIN", "TEST"],
  },
  {
    name: "sectors",
    menuName: "Sectors",
    pathName: "/sectors",
    menuIcon: () => <BusinessIcon />,
    authorizedAccounts: ["ADMIN", "TEST"],
  },
  { name: "typologies", menuName: "Typologies", pathName: "/typologies", menuIcon: () => <DeveloperBoardIcon />, authorizedAccounts: ["ADMIN", "TEST"] },
  { name: "celebrations", menuName: "Celebrations", pathName: "/celebrations", menuIcon: () => <CelebrationIcon />, authorizedAccounts: ["ADMIN", "TEST"] },
  { name: "providers", menuName: "Providers", pathName: "/providers", menuIcon: () => <HailIcon />, authorizedAccounts: ["ADMIN", "CDP", "TEST"] },
  { name: "providers-expertise", menuName: "Providers Expertise", pathName: "/providers-expertise", menuIcon: () => <ThumbUpIcon />, authorizedAccounts: ["TEST"] },
];

export default function DrawerLeft() {
  const { isLogged, onLogout, getUserType } = useAuth();
  const [linkList, setLinkList] = useState(views);
  const location = useLocation();
  const accountType = getUserType();

  useEffect(() => {
    updateLinkList(location.pathname);
  }, [location.pathname]);

  const updateLinkList = (pathName) => {
    const updatedLinkList = linkList.map((link) => {
      if (link.pathName === pathName) {
        return { ...link, isSelected: true };
      } else {
        return { ...link, isSelected: false };
      }
    });
    setLinkList(updatedLinkList);
  };

  return (
    isLogged && (
      <Container variant="permanent" anchor="left">
        <Logo src={DigitalProdLogo} alt="" />
        <List sx={{ width: "100%" }}>
          {linkList.reduce((acc, v) => {
            const isDisplayed = v.authorizedAccounts.find((accountRoleName) => accountRoleName === accountType);
            console.log("ii", v, isDisplayed);
            if (isDisplayed) {
              return [
                ...acc,
                <PageLink to={`/${v.name}`} key={v.name} isselected={"" + !!v.isSelected}>
                  <ListItemStyled button>
                    <ListItemIcon>{v.menuIcon()}</ListItemIcon>
                    <ListItemText primary={v.menuName} />
                  </ListItemStyled>
                </PageLink>,
              ];
            }
            return acc;
          }, [])}
        </List>
        <Divider />
        <List>
          <ListItemStyled button onClick={onLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemStyled>
        </List>
      </Container>
    )
  );
}
